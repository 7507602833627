import { CaretDownOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useLocation } from 'react-use';
export interface MarketplaceNavItem {
  text: string;
  path?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}
const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const router = useRouter();
  const { pathname } = router
  const location = useLocation();

  const getColor = (pathStr: string) => {
    if (pathname == "/gpts" || pathname == `/category/[id]`) {
      let end = location?.pathname?.toLowerCase().endsWith(pathStr?.toLowerCase())
      return end ? `var(--app-link-hover)` : `var(--app-text-color)`
    } else {
      return `var(--app-text-color)`
    }
  }

  const handleItemClick = (item: MarketplaceNavItem) => {
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    router.push(item?.path || '/');
  };
  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={() => handleItemClick(item)}
      >
        <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>{item.text}</span>
        {item.dropdownItems && <CaretDownOutlined />}
        {item.dropdownItems && (
          <div className={styles.navDropdown}>
            {item.dropdownItems.map((childItem, cIndex) =>
              renderItem(childItem, `nav-dropdown-item-${cIndex}`),
            )}
          </div>
        )}
      </div>
    );
  };
  return renderItem(item, `nav-item`);
};
export default NavItem;
