import type { ReactNode } from 'react';
import Link from 'next/link';
import { NavHamburger, NavMobileSearch } from '@/components/Layouts';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
import ChangeLanguage from '@/components/Common/Language';

const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  return (
    <div className={styles.mobileHeaderWarp}>
      <div className={styles.navToolbar}>
        <div className={styles.navToolbarSection}>
          <NavHamburger>{children}</NavHamburger>
        </div>

        <div className={styles.navToolbarSection}>
          <Link href="/" className={styles.logo}>
            <img
              className={styles.pietra}
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              title={AppSettings.name}
              loading="lazy"
            />
            <img
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: "32px", paddingLeft: "10px" }}
              loading="lazy"
            />
          </Link>
        </div>

        <div className={styles.navToolbarSection}>
          <NavMobileSearch className={styles.searchIcon} />
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
