import React, { useMemo } from 'react';
import styles from './index.module.less';
import { Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';

interface RetroTitleProps {
  text: string | React.ReactNode;
  textColor?: string;
  fontSize?: number;
  showAlert?: boolean;
}

const RetroTitle = ({ text, textColor, fontSize, showAlert = false }: RetroTitleProps) => {
  const { t } = useTranslation()

  const style = useMemo(() => {

    const textShadow = () => {
      if (!fontSize) {
        return 'none';
      }
      if (fontSize > 55) {
        return '4px 4px 0px var(--app-text-color)';
      }

      if (fontSize > 45) {
        return '3px 3px 0px var(--app-text-color)';
      }

      if (fontSize > 30) {
        return '2px 2px 0px var(--app-text-color)';
      }

      if (fontSize > 28) {
        return '1px 1px 0px var(--app-text-color)';
      }
    };

    const textStroke = () => {
      if (!fontSize) {
        return 'none';
      }
      if (fontSize >= 48) {
        return '2px var(--app-text-color)';
      }

      if (fontSize > 30 && fontSize < 48) {
        return `1px var(--app-text-color)`;
      }
    };
    return {
      color: textColor,
      fontSize: `${fontSize}px`,
      textShadow: textShadow(),
      textStroke: textStroke(),
    };
  }, [fontSize, textColor]);

  const titleContent = () => (
    <div style={{ padding: "10px 10px 40px 10px", position: "relative" }}>
      <div style={{ color: '#222' }}>
        {t("bookmarkDesc")}
      </div>
      <img src="/images/layouts/layout-like-icon.png"
        alt="like"
        style={{ height: 50, position: "absolute", bottom: 0, right: 10 }}
      />
    </div>
  )

  return (
    <div className={styles.retroTitle} style={style}>
      {showAlert ?
        <Tooltip title={titleContent}
          color={"#FFF"}
          placement='bottomLeft'>
          {text}
        </Tooltip> : text}
    </div >
  );
};

export default RetroTitle;
