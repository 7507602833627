import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';

const GlobalFooter = ({ show = true, className }: { show?: boolean; className?: string }) => {
  const { t } = useTranslation('common');

  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  const pathname = (typeof window !== 'undefined') ? window.location.pathname : "/"
  const tempBgColor = { background: (pathname == "/" || pathname == undefined) ? `var(--app-home-bg-rgb)` : `var(--foreground-rgb)` }

  return (
    <div className={cls} style={tempBgColor}>
      <div className={styles.titleContainer}>
        {/* GPTs Logo */}
        <RetroTitle
          // text={<>
          //   <img
          //     src={AppSettings.logoPath}
          //     alt={AppSettings.name}
          //     style={{ height: "32px" }}
          //   />
          //   <img
          //     src={AppSettings.namePath}
          //     alt={AppSettings.name}
          //     style={{ height: "32px" }}
          //   />
          // </>
          // }
          text={<div style={{ height: "32px" }}>
            <Image className={styles.logo}
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              loading="lazy"
              width={32}
              height={32}
            />
            <img
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: "32px" }}
            />
          </div>}
          textColor="#ff401a"
          fontSize={28} />
      </div>
      <div className={styles.subTitleContainer}>
        Copyright © 2024 Cyber Eel Tech Inc. All rights reserved.
      </div>

      <div className={styles.section}>
        <Link
          href="/privacy-policy"
          className={styles.link}
        >
          {t('privacyPolicy')}
        </Link>
        <Link
          href="/aboutus"
          className={styles.link}
        >
          {t('termsOfUs')}
        </Link>
        {/* <Link href="mailto:creators@pietrastudio.com" className={styles.link}>
          联系我们
        </Link> */}
      </div>

      {/* <div className={styles.section}>
        <Link href="/privacy-policy" className={styles.link}>
          Privacy Policy
        </Link>
        <Link href="/terms-of-service" className={styles.link}>
          Terms of Service
        </Link>
        <Link href="/fulfillment-services-agreement" className={styles.link}>
          Fulfillment Services Agreement
        </Link>
      </div>
      <div className={styles.section}>
        <Link href="https://www.instagram.com/shoppietra" target={'_blank'} className={styles.link}>
          Instagram
        </Link>
        <Link href="https://www.tiktok.com/@shoppietra" target={'_blank'} className={styles.link}>
          TikTok
        </Link>
      </div> */}
    </div>
  );
};

export default GlobalFooter;
